// __webpack_public_path__ is a webpack global that lets you inject a runtime
// value for publicPath instead of having to set it at build time
// eslint-disable-next-line @typescript-eslint/naming-convention
declare let __webpack_public_path__: string | undefined;

// Grab the configured CDN_URL *or* make a guess based on the URL of the asset bundle this
// is running in. This will only run on devbox and we can kill it after everyone has the updated
// docker-compose.yml that provides the RUNTIME_CONFIG option
// eslint-disable-next-line prefer-const
__webpack_public_path__ =
  window.__orinoco_config.CDN_URL ??
  (document.currentScript as HTMLScriptElement | null)?.src
    ?.split('/')
    ?.slice(0, -3)
    ?.join('/')
    ?.concat('/');

import {init as FSinit} from '@fullstory/browser';
import GlobalThis from 'globalthis';
import React from 'react';
import {createRoot} from 'react-dom/client';

// shim globalThis for older browsers.  Our twilio-video library uses it.
GlobalThis.shim();

const Root = React.lazy(() =>
  import('@member/app-shell/Root').then(m => ({default: m.Root})),
);

FSinit({
  orgId: 'Z17Y9',
  devMode: window.__orinoco_config.ENV !== 'prod',
});

// If a user direct links to an old /#/hash-style URL, we replace it with a direct
// admin/push-style URL before the React app actually loads. This only happens once,
// on page load. If a <Link> in the app has the #/ hardcoded, we won't catch that.
// Because we rely on removing the preceding / in the hash URL, we trust that the
// browser will append what was behind the #/ to the existing pathname in the URL,
// not create a new path from the root /. This may be fragile. Author: nickjs
if (location.hash.startsWith('#/')) {
  const rewrite = location.hash.replace('#/', '');

  const realSearch = location.search.replace(/\/$/, '');
  const hashSearch = rewrite.indexOf('?') !== -1; // if hash contains a search ?, combine it with the real one preceding the hash
  const search = hashSearch ? realSearch.replace('?', '&') : realSearch;

  history.replaceState(null, document.title, rewrite + search);
}

// In dev, require @axe-core/react - this will output details on any accessibility defects
// to the Chrome console every time a component updates
// https://github.com/dequelabs/axe-core-npm/tree/develop/packages/react
if (window.__orinoco_config.ENV === 'dev') {
  import('@axe-core/react').then(axe => {
    import('react-dom').then(ReactDOM => {
      axe.default(React, ReactDOM, 1000);
    });
  });
}

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);
root.render(
  <React.Suspense fallback={null}>
    <Root />
  </React.Suspense>,
);
